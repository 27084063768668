import { createCSVView4sap } from "../../viewComponents/CSVView4sap";

export default createCSVView4sap(
  "planiumChemicalSAPCSVView",
  "SAP用CSVダウンロードビュー",
  [
    "batchTaskMaster",
    "batchTaskPlan",
    "storageItemMaster",
    "purchasePlan",
    "batchTaskStoragePlan",
    "bomMaster",
    "itemMaster",
    "params",
    "storageMaster",
    "batchTaskProcessTimeMaster",
  ],
  []
);
