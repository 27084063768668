import { ViewTypeConfigSchema } from "@/domain/ViewTypeConfigSchema";
import { getSheetSchemaTypeMap } from "../../schema/allDependSheets";

// ビュー内で利用することがあるすべてのスキーマを定義

export const CSVViewComponentKey = "planiumChemicalSAPCSVView" as const;

export const CSVViewAllSchemaTypeMap = getSheetSchemaTypeMap([
  "batchTaskMaster",
  "batchTaskPlan",
  "storageItemMaster",
  "purchasePlan",
  "batchTaskStoragePlan",
  "bomMaster",
  "itemMaster",
  "params",
  "storageMaster",
  "batchTaskProcessTimeMaster",
]);

export const CSVViewAllConfigSchema = [] as const as ViewTypeConfigSchema<"">[];

export type CSVViewSchemaType = typeof CSVViewAllSchemaTypeMap;
