import { createDeliveryPlanView } from "../../viewComponents/deliveryPlan";

export default createDeliveryPlanView(
  "planiumChemicalDeliveryPlanView",
  "オーダー一覧",
  [
    "shippingPlan",
    "salesInput",
    "itemMaster",
    "storageItemMaster",
    "params",
    "adjustmentInput",
    "batchTaskPlan",
    "batchTaskMaster",
    "businessDayCalendar",
    "batchTaskProcessTimeMaster",
    "deviceMaster",
    "purchasePlan",
    "batchTaskRawMaterialPlan",
    "batchTaskStoragePlan",
    "switchingBatchTaskMaster",
    "rawMaterialMaster",
    "scoreSummaries",
  ],
  []
);
