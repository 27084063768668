import { VIEW_TYPE_KEY } from "@/viewType/constants";
import loadable from "@loadable/component";
import { getDependSheets } from "../../schema/allDependSheets";
import { StrictViewType } from "../../utils/types";
import {
  CSVViewAllConfigSchema as configSchema,
  CSVViewAllSchemaTypeMap as dataSchema,
} from "./types";

// index.tsx では、types.tsで定義したスキーマの一部分でビュータイプを作成するラッパー関数を提供する
// NOTE: 以下コピペ。対象のデータスキーマは、renderからのimport時のasで解決する

export const createCSVView4sap = (
  viewTypeKey: VIEW_TYPE_KEY,
  displayName: string,
  schemaKeys: (keyof typeof dataSchema)[],
  configKey: (typeof configSchema)[number]["key"][]
): StrictViewType<
  Pick<typeof dataSchema, (typeof schemaKeys)[number]>,
  (typeof configKey)[number]
> => {
  const schemaMap = Object.fromEntries(
    schemaKeys.map((key) => [key, {}])
  ) as Pick<typeof dataSchema, (typeof schemaKeys)[number]>;
  const configSchemas = configSchema.filter((config) =>
    configKey.includes(config.key)
  );

  const ViewRender = loadable(() => import("./render")) as StrictViewType<
    typeof schemaMap,
    (typeof configSchemas)[number]["key"]
  >["render"];

  return {
    key: viewTypeKey,
    displayName: displayName,
    render: ViewRender,
    dependSheets: getDependSheets(schemaKeys),
    configSchemas,
  };
};
