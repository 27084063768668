import { ViewTypeCellValue } from "@/domain/ViewTypeCell";
import { ViewTypeDependSheet } from "@/domain/ViewTypeDependSheet";
import { BatchTaskToPeopleCountMasterSchema } from "@/viewType/viewTypes/Planium/Chemical/schema/batch_mask_to_people_count_master";
import { BatchTaskStoragePlanSchema } from "@/viewType/viewTypes/Planium/Chemical/schema/batch_task_storage_plan";
import { BitMaskToPeopleCountMasterSchema } from "@/viewType/viewTypes/Planium/Chemical/schema/bit_mask_to_people_count_master";
import { AdjustmentInputSchema } from "./adjustment_input";
import { BatchTaskMasterSchema } from "./batch_task_master";
import { BatchTaskPlanSchema } from "./batch_task_plan";
import { BatchTaskProcessTimeMasterSchema } from "./batch_task_process_time_master";
import { BatchTaskRawMaterialPlanSchema } from "./batch_task_raw_material_plan";
import { BomByproductItemMasterSchema } from "./bom_byproduct_item_master";
import { BomMasterSchema } from "./bom_master";
import { BomRawMaterialMasterSchema } from "./bom_raw_material_master";
import { BusinessDayCalendarSchema } from "./business_day_calendar";
import { CommonStoragePluginMasterSchema } from "./common_storage_plugin_master";
import { ConnectionMasterSchema } from "./connection_master";
import { DeviceMaintenanceInputSchema } from "./device_maintenance_input";
import { DeviceMasterSchema } from "./device_master";
import { ItemMasterSchema } from "./item_master";
import { LinkTaskMasterSchema } from "./link_task_master";
import { MemoSchema } from "./memo";
import { NonBusinessDayAdjacentTaskPluginMasterSchema } from "./non_business_day_adjacent_task_plugin_master";
import { ParamsSchema } from "./params";
import { PluginMasterSchema } from "./plugin_master";
import { PurchasePlanSchema } from "./purchase_plan";
import { RawMaterialMasterSchema } from "./raw_material_master";
import { SalesInputSchema } from "./sales_input";
import { ScoreSummariesSchema } from "./score_summaries";
import { ShippingPlanSchema } from "./shipping_plan";
import { StockCriteriaMasterSchema } from "./stock_criteria_master";
import { StorageItemMasterSchema } from "./storage_item_master";
import { StorageMasterSchema } from "./storage_master";
import { SwitchingBatchTaskMasterSchema } from "./switching_batch_task_master";
import { TaskDeviceTypeMasterSchema } from "./task_device_type_master";
import { TaskPriorityPluginMasterSchema } from "./task_priority_plugin_master";
import { UtilityCostTypeMasterSchema } from "./utility_cost_type_master";

const AllDependSheetsTypeMap = {
  purchasePlan: {
    schema: {} as PurchasePlanSchema,
    displayName: "原料購買計画",
  },
  shippingPlan: {
    schema: {} as ShippingPlanSchema,
    displayName: "出荷計画",
  },
  batchTaskPlan: {
    schema: {} as BatchTaskPlanSchema,
    displayName: "タスク計画",
  },
  batchTaskRawMaterialPlan: {
    schema: {} as BatchTaskRawMaterialPlanSchema,
    displayName: "タスク原料使用計画",
  },
  batchTaskStoragePlan: {
    schema: {} as BatchTaskStoragePlanSchema,
    displayName: "タスク生成品保管計画",
  },
  salesInput: {
    schema: {} as SalesInputSchema,
    displayName: "販売計画",
  },
  itemMaster: {
    schema: {} as ItemMasterSchema,
    displayName: "品目マスタ",
  },
  stockCriteriaMaster: {
    schema: {} as StockCriteriaMasterSchema,
    displayName: "在庫基準マスタ",
  },
  rawMaterialMaster: {
    schema: {} as RawMaterialMasterSchema,
    displayName: "原料マスタ",
  },
  bomMaster: {
    schema: {} as BomMasterSchema,
    displayName: "BOMマスタ",
  },
  bomRawMaterialMaster: {
    schema: {} as BomRawMaterialMasterSchema,
    displayName: "BOM原材料マスタ",
  },
  bomByproductItemMaster: {
    schema: {} as BomByproductItemMasterSchema,
    displayName: "BOM副生成品マスタ",
  },
  deviceMaster: {
    schema: {} as DeviceMasterSchema,
    displayName: "装置マスタ",
  },
  storageMaster: {
    schema: {} as StorageMasterSchema,
    displayName: "保管所マスタ",
  },
  storageItemMaster: {
    schema: {} as StorageItemMasterSchema,
    displayName: "保管所品目マスタ",
  },
  connectionMaster: {
    schema: {} as ConnectionMasterSchema,
    displayName: "装置・保管所接続マスタ",
  },
  batchTaskMaster: {
    schema: {} as BatchTaskMasterSchema,
    displayName: "タスクマスタ",
  },
  batchTaskProcessTimeMaster: {
    schema: {} as BatchTaskProcessTimeMasterSchema,
    displayName: "タスク所要時間マスタ",
  },
  switchingBatchTaskMaster: {
    schema: {} as SwitchingBatchTaskMasterSchema,
    displayName: "タスク切替マスタ",
  },
  linkTaskMaster: {
    schema: {} as LinkTaskMasterSchema,
    displayName: "連続工程マスタ",
  },
  utilityCostTypeMaster: {
    schema: {} as UtilityCostTypeMasterSchema,
    displayName: "ユーティリティ種別マスタ",
  },
  batchTaskUtilityMaster: {
    schema: {} as UtilityCostTypeMasterSchema,
    displayName: "タスクユーティリティマスタ",
  },
  businessDayCalendar: {
    schema: {} as BusinessDayCalendarSchema,
    displayName: "営業日カレンダー",
  },
  adjustmentInput: {
    schema: {} as AdjustmentInputSchema,
    displayName: "在庫実績入力",
  },
  deviceMaintenanceInput: {
    schema: {} as DeviceMaintenanceInputSchema,
    displayName: "装置メンテナンス計画",
  },
  taskDeviceTypeMaster: {
    schema: {} as TaskDeviceTypeMasterSchema,
    displayName: "タスク装置タイプマスタ",
  },
  memo: {
    schema: {} as MemoSchema,
    displayName: "メモ",
  },
  bitMaskToPeopleCountMaster: {
    schema: {} as BitMaskToPeopleCountMasterSchema,
    displayName: "装置人員数マスタ",
  },
  batchTaskToPeopleCountMaster: {
    schema: {} as BatchTaskToPeopleCountMasterSchema,
    displayName: "タスク人員数マスタ",
  },
  params: {
    schema: {} as ParamsSchema,
    displayName: "最適化パラメータ",
  },
  pluginMaster: {
    schema: {} as PluginMasterSchema,
    displayName: "プラグインマスタ",
  },
  scoreSummaries: {
    schema: {} as ScoreSummariesSchema,
    displayName: "最適化スコアサマリ",
  },
  commonStoragePluginMaster: {
    schema: {} as CommonStoragePluginMasterSchema,
    displayName: "共通タンクプラグインマスタ",
  },
  nonBusinessDayAdjacentTaskPluginMaster: {
    schema: {} as NonBusinessDayAdjacentTaskPluginMasterSchema,
    displayName: "非営業日タスク隣接プラグインマスタ",
  },
  taskPriorityPluginMaster: {
    schema: {} as TaskPriorityPluginMasterSchema,
    displayName: "使用タスク優先度プラグインマスタ",
  },
} as const satisfies Record<
  string,
  {
    schema: Record<string, ViewTypeCellValue>; // データの型変換をsrc/viewType/parser.tsの関数で行なっているため、そこで対応していない型は知識を持てない。特にarray
    displayName: string;
  }
>;

export const getDependSheets = <
  SheetKeys extends (keyof typeof AllDependSheetsTypeMap)[],
>(
  keys: SheetKeys
): ViewTypeDependSheet<
  (typeof keys)[number],
  {
    [k in (typeof keys)[number]]: keyof (typeof AllDependSheetsTypeMap)[k]["schema"] &
      string;
  }
>[] => {
  return keys.map((key) => ({
    key,
    displayName: AllDependSheetsTypeMap[key].displayName,
    isReadAll: true,
  }));
};

export const getSheetSchemaTypeMap = <
  SheetKeys extends (keyof typeof AllDependSheetsTypeMap)[],
>(
  keys: SheetKeys
) => {
  return Object.fromEntries(
    keys.map((key) => [key, AllDependSheetsTypeMap[key].schema])
  ) as {
    [k in SheetKeys[number]]: (typeof AllDependSheetsTypeMap)[k]["schema"];
  };
};
