import { ViewType } from "@/domain/ViewType";
import { viewTypePlaniumChemicalCSV4SAPViews } from "./CSVView4sap";
import { viewTypePlaniumChemicalBatchGanttViews } from "./batchGanttView";
import { viewTypePlaniumChemicalDeliveryPlanViews } from "./deliveryPlans";
import { viewTypePlaniumChemicalItemStockViews } from "./itemStockView";
import { viewTypePlaniumChemicalPSIViews } from "./psi";
import { viewTypePlaniumChemicalStorageStockViews } from "./storageStockView";

/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
export const viewTypesPlaniumChemical = [
  ...viewTypePlaniumChemicalBatchGanttViews,
  ...viewTypePlaniumChemicalCSV4SAPViews,
  ...viewTypePlaniumChemicalDeliveryPlanViews,
  ...viewTypePlaniumChemicalItemStockViews,
  ...viewTypePlaniumChemicalPSIViews,
  ...viewTypePlaniumChemicalStorageStockViews,
] satisfies ViewType<any, any>[];
