import { createPSIView } from "../../viewComponents/psi";

export default createPSIView(
  "planiumChemicalPSIView",
  "PSI表",
  [
    "memo",
    "batchTaskPlan",
    "shippingPlan",
    "purchasePlan",
    "businessDayCalendar",
    "adjustmentInput",
    "salesInput",
    "batchTaskMaster",
    "params",
    "batchTaskStoragePlan",
    "batchTaskRawMaterialPlan",
    "itemMaster",
    "deviceMaster",
    "batchTaskProcessTimeMaster",
    "switchingBatchTaskMaster",
    "utilityCostTypeMaster",
    "batchTaskUtilityMaster",
    "deviceMaintenanceInput",
    "storageMaster",
    "stockCriteriaMaster",
    "storageItemMaster",
  ],
  ["cut"]
);
