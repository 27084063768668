import { createItemStockView } from "../../viewComponents/itemStockView";

export default createItemStockView(
  "planiumChemicalItemStockView",
  "品目在庫推移ビュー",
  [
    "batchTaskPlan",
    "shippingPlan",
    "purchasePlan",
    "businessDayCalendar",
    "adjustmentInput",
    "salesInput",
    "batchTaskMaster",
    "params",
    "batchTaskStoragePlan",
    "batchTaskRawMaterialPlan",
    "itemMaster",
    "deviceMaster",
    "batchTaskProcessTimeMaster",
    "switchingBatchTaskMaster",
    "utilityCostTypeMaster",
    "batchTaskUtilityMaster",
    "deviceMaintenanceInput",
    "storageMaster",
    "stockCriteriaMaster",
    "storageItemMaster",
  ],
  ["itemStorageType"]
);
