import { ViewTypeConfigSchema } from "@/domain/ViewTypeConfigSchema";
import { getSheetSchemaTypeMap } from "../../schema/allDependSheets";

// ビュー内で利用することがあるすべてのスキーマを定義

export const BatchGanttViewComponentKey =
  "planiumChemicalBatchGanttView" as const;

export const BatchGanttViewAllSchemaTypeMap = getSheetSchemaTypeMap([
  "batchTaskMaster",
  "batchTaskPlan",
  "batchTaskProcessTimeMaster",
  "batchTaskRawMaterialPlan",
  "batchTaskStoragePlan",
  "bomByproductItemMaster",
  "bomMaster",
  "bomRawMaterialMaster",
  "deviceMaster",
  "deviceMaintenanceInput",
  "itemMaster",
  "storageItemMaster",
  "switchingBatchTaskMaster",
  "taskDeviceTypeMaster",
  "params",
  "businessDayCalendar",
  "linkTaskMaster",
  "scoreSummaries",
  "utilityCostTypeMaster",
  "batchTaskUtilityMaster",
  "bitMaskToPeopleCountMaster",
  "batchTaskToPeopleCountMaster",
  "salesInput",
  "shippingPlan",
  "rawMaterialMaster",
  "purchasePlan",
  "connectionMaster",
  "storageMaster",
  "adjustmentInput",
  "commonStoragePluginMaster",
  "nonBusinessDayAdjacentTaskPluginMaster",
  "taskPriorityPluginMaster",
]);

export const BatchGanttViewAllConfigSchema =
  [] as const as ViewTypeConfigSchema<"">[];

export type BatchGanttViewSchemaType = typeof BatchGanttViewAllSchemaTypeMap;
