import { createBatchGanttView } from "../../viewComponents/batchGantt";

export default createBatchGanttView(
  "planiumChemicalBatchGanttView",
  "ガントチャート",
  [
    "batchTaskMaster",
    "batchTaskPlan",
    "batchTaskProcessTimeMaster",
    "batchTaskRawMaterialPlan",
    "batchTaskStoragePlan",
    "bomByproductItemMaster",
    "bomMaster",
    "bomRawMaterialMaster",
    "deviceMaster",
    "deviceMaintenanceInput",
    "itemMaster",
    "storageItemMaster",
    "switchingBatchTaskMaster",
    "taskDeviceTypeMaster",
    "params",
    "businessDayCalendar",
    "linkTaskMaster",
    "scoreSummaries",
    "utilityCostTypeMaster",
    "batchTaskUtilityMaster",
    "bitMaskToPeopleCountMaster",
    "batchTaskToPeopleCountMaster",
    "salesInput",
    "shippingPlan",
    "rawMaterialMaster",
    "purchasePlan",
    "connectionMaster",
    "storageMaster",
    "adjustmentInput",
    "commonStoragePluginMaster",
    "nonBusinessDayAdjacentTaskPluginMaster",
    "taskPriorityPluginMaster",
  ],
  []
);
