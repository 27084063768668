import { isLocalhost } from "../utils/isLocalhost";

/**
 * NOTE: signinWithRedirectの利用にあたり、iframeのstorage partitioning問題を回避するため、authDomainをデフォルトから変更する
 * ただし、ローカルの場合はfirebase sdkが対応できないため、signInWithPopupを利用するとしてauthDomainはデフォルト
 * https://cloud.google.com/identity-platform/docs/web/redirect-best-practices?hl=ja
 * https://github.com/firebase/firebase-js-sdk/issues/7342
 */
export const FIREBASE_CONFIG = (() => {
  switch (process.env.APP_ENV) {
    case "staging":
      return {
        apiKey: "AIzaSyD5eEra_sEoIvCiCJ-siMpLLYVqEiqK-eA",
        authDomain: isLocalhost()
          ? "aa-sheets-ui-stg-gcp.firebaseapp.com"
          : location.host,
        databaseURL:
          "https://aa-sheets-ui-stg-gcp-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "aa-sheets-ui-stg-gcp",
        storageBucket: "aa-sheets-ui-stg-gcp.appspot.com",
        messagingSenderId: "261731864615",
        appId: "1:261731864615:web:6e615c6f4cbde284ae3e02",
        measurementId: "G-CEVMLVZ59K",
      };
    case "production":
      return {
        apiKey: "AIzaSyB-0GX9pYOnrFJHzCmEQuR6MKdlMRb4qj8",
        authDomain: isLocalhost()
          ? "aa-sheets-ui-gcp.firebaseapp.com"
          : location.host,
        databaseURL:
          "https://aa-sheets-ui-prod-gcp-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "aa-sheets-ui-prod-gcp",
        storageBucket: "aa-sheets-ui-prod-gcp.appspot.com",
        messagingSenderId: "108008326182",
        appId: "1:108008326182:web:58ed25a7af3b91bccb52e7",
        measurementId: "G-4QSNK4KZ0D",
      };
    // default:
    //   return {
    //     apiKey: "AIzaSyDgDSPiz8FvlFlYmGoXllKEeP-fHVeurPg",
    //     authDomain: isLocalhost()
    //       ? "aa-sheets-ui-dev-gcp.firebaseapp.com"
    //       : location.host,
    //     databaseURL:
    //       "https://aa-sheets-ui-dev-gcp-default-rtdb.asia-southeast1.firebasedatabase.app",
    //     projectId: "aa-sheets-ui-dev-gcp",
    //     storageBucket: "aa-sheets-ui-dev-gcp.appspot.com",
    //     messagingSenderId: "628817213425",
    //     appId: "1:628817213425:web:109ff7380b0a7e85427650",
    //     measurementId: "G-LT2JK7D4YM",
    //   };
    default:
      return {
        apiKey: "AIzaSyDY5TxrZ8_Zcl6AP9yCZzf01nZcuOEOal4",
        authDomain: isLocalhost()
          ? "aa-sandbox-sys-alpaca-gcp.firebaseapp.com"
          : location.host,
        databaseURL:
          "https://aa-sandbox-sys-alpaca-gcp-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "aa-sandbox-sys-alpaca-gcp",
        storageBucket: "aa-sandbox-sys-alpaca-gcp.appspot.com",
        messagingSenderId: "258907623586",
        appId: "1:258907623586:web:f0cef3fcbec9decb9307fb",
        measurementId: "G-F2Y7MYDY4K",
      };
  }
})();
