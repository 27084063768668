import { ViewTypeConfigSchema } from "@/domain/ViewTypeConfigSchema";
import { getSheetSchemaTypeMap } from "../../schema/allDependSheets";

// ビュー内で利用することがあるすべてのスキーマを定義

export const PSIViewComponentKey = "planiumChemicalPSIView" as const;

export const PSIViewAllSchemaTypeMap = getSheetSchemaTypeMap([
  "memo",
  "batchTaskPlan",
  "shippingPlan",
  "purchasePlan",
  "businessDayCalendar",
  "adjustmentInput",
  "salesInput",
  "batchTaskMaster",
  "params",
  "batchTaskStoragePlan",
  "batchTaskRawMaterialPlan",
  "itemMaster",
  "deviceMaster",
  "batchTaskProcessTimeMaster",
  "switchingBatchTaskMaster",
  "utilityCostTypeMaster",
  "batchTaskUtilityMaster",
  "deviceMaintenanceInput",
  "storageMaster",
  "stockCriteriaMaster",
  "storageItemMaster",
]);

export const PSIViewAllConfigSchema = [
  {
    key: "cut",
    displayName: "表示タイプ (Item / Storage)",
    type: "string",
    typeFields: null,
    typeParameters: null,
  },
] as const as ViewTypeConfigSchema<"cut">[];

export type PSIViewSchemaType = typeof PSIViewAllSchemaTypeMap;
