import {
  ContextualMenu,
  DirectionalHint,
  FontIcon,
  IContextualMenuItem,
  IconButton,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import classNames from "classnames";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useAppRouteParams } from "../../../AppRoutes";
import {
  CLOSED_SIDE_MENU_WIDTH,
  MAXIMUM_RESIZE_SIDE_MENU_WIDTH,
  MINIMUM_RESIZE_SIDE_MENU_WIDTH,
  OPEN_SIDE_MENU_WIDTH,
} from "../../../const";
import { Sheet } from "../../../domain/Sheet";
import { checkIsPublishedByFileUpload } from "../../../domain/Version";
import { useResizeHandler } from "../../../hooks/useResizeHandler";
import { contextMenuStyle } from "../../../styles";
import { themePrimaryDark } from "../../../themes";
import { useOrganizationSession } from "../../InOrganizationProvider/SessionProvider";
import { getVersionSessionKey } from "../../InOrganizationProvider/SessionProvider/helpers";
import {
  SortableTmpMenuSet,
  TmpMenuSetDivider,
  TmpMenuSetOptimizeSummary,
} from "../helpers";

import { SheetsMenu } from "./SheetsMenu";
import { ViewsMenu } from "./ViewsMenu";
import styles from "./index.module.css";

import { useAppOrgsAndInvitedOrgs } from "@/components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import { useAuthorizedSheets } from "@/hooks/useAuthorizedCollections";

const buttonStyle: React.CSSProperties = {
  display: "inline-block",
  width: "22px",
  height: "22px",
  textAlign: "center",
  color: "inherit",
};

/**
 * @description メニュ-画面左に常にあるメニュー. バージョン画面や、トップページで、表示内容が変わる
 *
 */
export const AppSideNavLocal: React.FC = () => {
  const navigate = useNavigate();
  const { organizations } = useAppOrgsAndInvitedOrgs();
  const { versionId, organizationId } = useAppRouteParams();
  const organization = organizations.find(
    (org) => org.org_id === organizationId
  );
  const { versions, isLoadedVersions } = useVersions();
  const version = versions.find((v) => v.id === versionId);
  const [sheets, isLoadedSheets] = useAuthorizedSheets(versionId);

  const [menuWidth, setMenuWidth] = useState(OPEN_SIDE_MENU_WIDTH);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  // NOTE: バージョンにアクセスしていることを書き込む
  const { updateSessionData } = useOrganizationSession();
  const versionSessionKey = getVersionSessionKey(versionId);

  useEffect(() => {
    updateSessionData(versionSessionKey, {
      selectingVersionId: versionId,
    });
  }, [versionId, versionSessionKey]);
  useEffect(() => {
    return () => {
      updateSessionData(versionSessionKey, null);
    };
  }, [versionSessionKey]);

  const rootRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const onResizeHandlerMouseDown = useResizeHandler({
    type: "right",
    targetRef: rootRef,
    onResizeStart: () => {
      setIsResizing(true);
    },
    onResize: (width, height) => {
      if (
        width <= MINIMUM_RESIZE_SIDE_MENU_WIDTH ||
        width >= MAXIMUM_RESIZE_SIDE_MENU_WIDTH
      )
        return;
      setMenuWidth(width);
    },
    onResizeEnd: () => {
      setIsResizing(false);
    },
  });

  const topMenuNodeId = useId();
  const [contexMenuType, setContexMenuType] = useState<"versionInfo" | null>(
    null
  );
  const [isOpenChildItems, setIsOpenChildItems] = useState(false);
  const topContextMenuItems: IContextualMenuItem[] = [
    {
      key: "section1",
      text: "バージョン情報",
      onClick: () => {
        navigate(
          `/organizations/${organizationId}/versions/${versionId}/version_info`
        );
      },
      iconProps: {
        iconName: "info",
        style: { color: "var(--figma-color-lightBlack)" },
      },
    },
  ];
  const resultScoreSummary: Sheet | null = useMemo(
    () =>
      sheets.find(
        ({ sheetOptimizeType }) =>
          sheetOptimizeType === "result_score_summaries"
      ) ?? null,
    [sheets]
  );

  const isVisibleContextMenu = organization?.is_sheet_enabled === true;

  /**
   * NOTE
   * versionIdがURLに入っていない時 or ファイル最適化バージョンの時は表示しない
   */
  if (
    !versionId ||
    !isLoadedVersions ||
    !version ||
    checkIsPublishedByFileUpload(version)
  ) {
    return null;
  }
  return (
    <div
      className={classNames(styles.root, {
        [styles.isResizing]: isResizing,
        [styles.isCollapsed]: isMenuCollapsed,
      })}
      style={{
        width: `${isMenuCollapsed ? CLOSED_SIDE_MENU_WIDTH : menuWidth}px`,
        minWidth: `${CLOSED_SIDE_MENU_WIDTH}px`,
      }}
      ref={rootRef}
    >
      <>
        <div className={styles.firstMenu}>
          <div
            className={styles.content}
            id={topMenuNodeId}
            onClick={() =>
              setContexMenuType((prev) => (!prev ? "versionInfo" : null))
            }
          >
            {!isMenuCollapsed ? (
              <div className={styles.textWithTooltip}>
                <TooltipHost
                  content={version.title}
                  hidden={contexMenuType !== null}
                >
                  <div className={styles.title}>
                    {isMenuCollapsed ? "" : version.title}
                  </div>
                </TooltipHost>
              </div>
            ) : null}
            <div
              className={classNames({
                [styles.rightButtons]: !isMenuCollapsed,
              })}
            >
              {!isMenuCollapsed && isVisibleContextMenu ? (
                <IconButton
                  iconProps={{
                    iconName: `${
                      isOpenChildItems ? "ChevronUpSmall" : "ChevronDownSmall"
                    }`,
                  }}
                  style={buttonStyle}
                  styles={{ icon: { fontSize: "12px" } }}
                  theme={themePrimaryDark}
                  onMouseDown={() => {
                    setIsOpenChildItems((state) => !state);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              ) : null}
              <IconButton
                iconProps={{
                  iconName: "More",
                }}
                style={{
                  ...buttonStyle,
                  ...(isMenuCollapsed ? { pointerEvents: "none" } : undefined),
                }}
                styles={{ icon: { fontSize: "12px" } }}
                theme={themePrimaryDark}
              />
            </div>
          </div>
          {isOpenChildItems && !isMenuCollapsed && isVisibleContextMenu ? (
            <div className={styles.summaryContainer}>
              <div className={styles.tmpMenuSetOptimizeSummary}>
                <TmpMenuSetOptimizeSummary version={version} />
                {resultScoreSummary ? (
                  <div className={styles.link}>
                    <a
                      href={`#/organizations/${organizationId}/versions/${versionId}/sheets/${resultScoreSummary.id}`}
                    >
                      バージョンサマリを詳しく見る
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        <TmpMenuSetDivider />
        <div className={styles.secondRoot}>
          <div className={styles.secondMenu}>
            {!isMenuCollapsed ? (
              <>
                <div className={styles.divider}>
                  <ViewsMenu />
                </div>
                <SheetsMenu />
              </>
            ) : (
              <>
                <TooltipHost content="ビュー">
                  <SortableTmpMenuSet
                    id={"AnalyticsView"}
                    iconName="AnalyticsView"
                    to={`/organizations/${organizationId}/versions/${versionId}?activeTab=views`}
                  />
                </TooltipHost>
                <TooltipHost content="シート">
                  <SortableTmpMenuSet
                    id={"Documentation"}
                    iconName="Documentation"
                    to={`/organizations/${organizationId}/versions/${versionId}?activeTab=sheets`}
                  />
                </TooltipHost>
              </>
            )}
          </div>
        </div>
        <TmpMenuSetDivider />
        <div
          className={styles.thirdRoot}
          onMouseDownCapture={(e) => {
            setIsMenuCollapsed(!isMenuCollapsed);
          }}
        >
          <div className={styles.chevron}>
            <FontIcon
              iconName={
                !isMenuCollapsed ? "DoubleChevronLeft" : "DoubleChevronRight"
              }
            />
          </div>
        </div>
        <div
          className={styles.rootResizeHandler}
          onMouseDown={() => {
            if (isMenuCollapsed) return;
            onResizeHandlerMouseDown();
          }}
        />
        <ContextualMenu
          styles={contextMenuStyle}
          target={`#${topMenuNodeId}`}
          directionalHint={DirectionalHint.bottomRightEdge}
          items={topContextMenuItems}
          hidden={contexMenuType !== "versionInfo"}
          onDismiss={() => {
            setContexMenuType(null);
          }}
        />
      </>
    </div>
  );
};
